import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ConfigBaseService, IAccount, IConfiguration, MDS_CONFIG_SERVICE } from '@mds/angular-core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { createAuthConfig } from '../config/auth-config.factory';
import { AuthConfigModel, MDS_AUTH_CONFIG_MAP, MDS_AUTH_DEBUG } from '../models/auth-config.model';
import { DynamicScopesService } from './dynamic-scopes.service';

interface OAuthClaims {
  sub: string;
  name: string;
}

@Injectable()
export class AuthenticationService extends AuthService {
  private readonly authState = 'auth-state';
  private readonly authConfigModel$ = new BehaviorSubject<AuthConfigModel | null>(null);

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private dynamicScopesService: DynamicScopesService,
    @Inject(MDS_CONFIG_SERVICE) configService: ConfigBaseService,
    @Inject(MDS_AUTH_DEBUG) showDebugInformation: boolean,
    @Inject(MDS_AUTH_CONFIG_MAP) authConfigMap: (config: IConfiguration) => AuthConfigModel
  ) {
    super();
    this.isAuthenticatedSubject$.next(false);

    this.oauthService.events.subscribe((e) => {
      switch (e.type) {
        case 'token_received':
          this.setUserIdFromAccount();

          if (this.state) {
            router.navigateByUrl(this.state);
            this.clearState();
          }
          break;

        case 'token_refresh_error':
          this.router.navigate(['errors', '401'], { relativeTo: null });
          break;

        case 'session_terminated':
        case 'session_error':
          this.logout();
          break;
      }

      const hasToken = this.oauthService.hasValidAccessToken();
      if (this.isAuthenticatedSubject$.value !== hasToken) {
        this.isAuthenticatedSubject$.next(hasToken);
      }
    });

    configService.isInitialized$
      .pipe(
        first(),
        map(() => configService.getConfig()),
        map((x) => authConfigMap(x)),
        tap((authConfigModel) => this.authConfigModel$.next(authConfigModel)),
        map((x) => createAuthConfig(x, showDebugInformation))
      )
      .subscribe((x) => {
        this.oauthService.configure(x);

        const hasToken = this.oauthService.hasValidAccessToken();
        if (navigator?.onLine === false && hasToken) {
          this.isAuthenticatedSubject$.next(true);

          fromEvent(window, 'online')
            .pipe(first())
            .subscribe(() => {
              this.oauthService.loadDiscoveryDocumentAndTryLogin().finally(() => this.isLoadedSubject.next(true));
              this.oauthService.setupAutomaticSilentRefresh();
            });
        } else {
          this.oauthService.loadDiscoveryDocumentAndTryLogin().finally(() => this.isLoadedSubject.next(true));
          this.oauthService.setupAutomaticSilentRefresh();
        }

        if (hasToken) {
          this.setUserIdFromAccount();
        }
      });
  }

  get account(): IAccount {
    const claims = this.getClaims();
    return {
      id: claims?.sub,
      displayName: claims?.name,
    };
  }

  private setUserIdFromAccount(): void {
    this.setUserId(this.account.id);
  }

  private getClaims(): OAuthClaims {
    return this.oauthService.getIdentityClaims() as OAuthClaims;
  }

  private get state(): string {
    return localStorage.getItem(this.authState) ?? '';
  }

  private set state(value: string) {
    localStorage.setItem(this.authState, value);
  }

  private clearState(): void {
    localStorage.removeItem(this.authState);
  }

  viewAccount(): void {
    this.authConfigModel$
      .asObservable()
      .pipe(
        filter((x) => x != null),
        map((x) => x as AuthConfigModel)
      )
      .subscribe((x) => (window.location.href = x.myAccountUri));
  }

  get tokenEndpoint$(): Observable<string> {
    return this.isAuthenticated$.pipe(map(() => this.oauthService.tokenEndpoint as string));
  }

  login(targetUrl?: string): void {
    targetUrl ??= this.router.url;
    this.state = targetUrl;

    const currentOrganization = this.dynamicScopesService.get('planzer.id.orgid');
    const customQueryParams = this.oauthService.customQueryParams as { resource: string };

    if (currentOrganization != null) {
      const scopes = this.oauthService.scope?.split(' ').filter((s) => !s.startsWith('.default'));
      scopes?.unshift(`.default?orgId=${currentOrganization}`);
      scopes?.unshift(`${customQueryParams.resource}/users:me`);
      this.oauthService.scope = scopes?.join(' ');
    }

    this.oauthService.initLoginFlow();
  }

  logout(): void {
    super.logout();
    this.oauthService.logOut();
  }
}
